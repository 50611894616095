import React, { FunctionComponent, useEffect } from 'react';
import $ from 'jquery';

import { fetchJsonWithDefaultHeaders } from 'common/http';
import { defaultHeaders } from 'common/http/index';
import I18n from 'common/i18n';
import { Filter } from '../types';
import AutocompleteField from './AutocompleteField';
import {
  alphabetizeFilters,
  generateProvenanceFilter,
  restructureCategories
} from 'accessibleBrowseFilters/helpers';
import { ForgeDivider, ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';
import FeatureFlags from 'common/feature_flags';
import { changeUrlBasedOnFilterParamsAndReload, removeQueryStringFromUrlAndReload } from '../helpers';
import RetrofitBrowseFilterSection from './RetrofitBrowseFilterSection';

interface Props {
  isMobile: boolean;
}

const AccessibleBrowseFiltersWrapper: FunctionComponent<Props> = (props) => {
  const scope = 'controls.browse.browse3.filter';
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const [tags, setTags] = React.useState<Filter[]>([]); // making an array because there could be multiple "tag-like" sections
  const fetchFilters = async () => {
    const apiPath = '/browse/facets_info';
    const options = {
      credentials: 'same-origin',
      headers: defaultHeaders
    };

    const result = await fetchJsonWithDefaultHeaders(apiPath, options);
    organizeFacets(result.facets);
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const organizeFacets = (facets: Filter[]) => {
    // get anything that isn't Tags (for now) and alphabetize their options
    const filterFacets: Filter[] = facets.filter((facet) => !facets.some((f) => facet.title === 'Tags'));
    restructureCategories(filterFacets);

    // Add authority facet if needed
    if (FeatureFlags.value('show_provenance_facet_in_catalog')) {
      filterFacets.unshift(generateProvenanceFilter());
    }
    setFilters(alphabetizeFilters(filterFacets));

    // EN-64265 also need to filter out anything with more than x options
    setTags(facets.filter((facet) => !facets.some((f) => facet.title != 'Tags')));
  };

  const handleCloseClick = () => {
    const mobileDiv = document.getElementById('mobile-accessible-browse-filters');
    if (mobileDiv) {
      mobileDiv.style.display = 'none';
      const $mainPageElements = $(
        [
          '.siteContentWrapper > ul.featuredViews',
          '.browse2-search',
          '.browse2-mobile-filter',
          '.browse2-results-pane',
          '#siteHeader',
          '#site-chrome-header',
          '#siteFooter',
          '#site-chrome-footer'
        ].join(', ')
      );
      $mainPageElements.show();
    }
  };

  const onTagSelect = (tagValue: string) => {
    changeUrlBasedOnFilterParamsAndReload('tags', tagValue);
  };

  const onTagClear = () => {
    removeQueryStringFromUrlAndReload('tags');
  };

  return (
    <div>
      <div className="filters-title">
        <span slot="start" id="accessible-browse-filters-title" className="forge-typography--title">
          {I18n.t('filters', { scope })}
        </span>
        {props.isMobile && (
          <div className="icon-button">
            <ForgeIconButton dense={true}>
              <button
                type="button"
                aria-label="Close filter drawer"
                data-testid="close-mobile-filters"
                onClick={handleCloseClick}
              >
                <ForgeIcon name="close" />
              </button>
            </ForgeIconButton>
          </div>
        )}
      </div>
      <div className="filter-sections">
        <ForgeDivider />
        {filters.map((filter) => (
          <RetrofitBrowseFilterSection
            title={filter.title}
            options={filter.options}
            key={filter.title}
            filterParam={filter.param}
            isMobile={props.isMobile}
          />
        ))}
        {tags.map((tag) => (
          <AutocompleteField
            key={tag.param}
            fieldInfo={tag}
            isMobile={props.isMobile}
            onAutocompleteSelect={onTagSelect}
            onClear={onTagClear}
          />
        ))}
      </div>
    </div>
  );
};

export default AccessibleBrowseFiltersWrapper;
